
<template>
  <div>
    <page-header title='Certificados'></page-header>
    <v-row justify="end" class="mb-0">
      <v-btn color="white" depressed rounded class="mx-2 mr-10 mt-n3" @click="$router.push({name: 'TeacherCertificateList'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-arrow-right-bold-circle-outline</v-icon> 
        CERTIFICADOS PROFESSORES
      </v-btn>
    </v-row>
    
    <v-row>
      <v-col class="pt-0">
        <v-card style="border-radius:12px" flat outlined>
          <v-card-title primary-title class='primary white--text py-1' >
            <div class="d-flex flex-column">
              <div class="text-subtitle-2">Curso</div>
              <div style="word-break: break-word">{{course.name}}</div>
            </div>
            <v-spacer></v-spacer>
            <course-details-dialog class="ml-2" :course="course"></course-details-dialog>
          </v-card-title>
          <card-loading-transparent class="pt-3" v-if="loading"/>
          <v-card-text class="pt-3" v-if="!loading">
           <v-data-table
              :headers="headers"
              :items-per-page="10"
              :items="enrolleds_list"
              :search="search"
            >
              <template v-slot:top>
                <v-text-field  v-model="search" label="Buscar" class="mx-4"></v-text-field>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn small text color="primary" @click="openCertificate(item)" rounded outlined>
                  <v-icon left>mdi-clipboard-text</v-icon>Abrir certificado
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <dialog-show-file 
      v-if="dialog"
      title="Certificado" 
      @closeDialog="handleCloseDialog" 
      :dialog_file="dialog" 
      file_kind="application/pdf" 
      :file_url="file_url"
    ></dialog-show-file>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'ConcludedCoursesCertificateList',
  components: {
    CourseDetailsDialog: () => import('../shared/CourseDetailsDialog.vue')
  },
  data() {
    return {
      course: {},
      headers: [
        { text: '#', value: 'id'},
        { text: 'Aluno', value: 'name'},
        { text: 'E-mail', value: 'email'},
        { text: 'Telefone', value: 'phone'},
        { text: 'Certificado', value: 'actions', align: 'center', sortable: false},
      ],
      enrolleds_list: [],
      search: '',
      loading: false,
      loading_certificate: false,
      file_url: null,
      dialog: false,
    }
  },
  methods: {
    getInfos(){
      this.getCourseInfo();
      this.getCertificates();
    },
    getCourseInfo(){
      Api.Course.show(this.$route.params.id).then(r=>r.data).then(d=>{
        this.course = d
      });
    },
    openCertificate(item){
      this.file_url = item.certificate_file_url;
      this.dialog = true;
    },
    handleCloseDialog(){
      this.dialog = false;
    },
    getCertificates(){
      let filter = {
        course_id: this.$route.params.id,
        certificate_list_request: true
      }
      Api.Enrolled.index(filter).then(r=>r.data).then(d=>{
        this.enrolleds_list = d
      });
    },
  },
  mounted () {
    this.getInfos();
  },
}
</script>

<style scoped>

</style>